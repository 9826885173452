@import "variables";
@import "notification";

*,
:after,
:before {
  box-sizing: border-box;
}

p {
  color: #000;
  font-size: 16px;
  line-height: 25px;
}
.error-text {
  color: #ff0000;
}

h1 {
  font-size: 45px;
  line-height: 68px;
  font-weight: 700;
}

h2 {
  font-style: 35px;
  line-height: 53px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

h4 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

audio {
  &:focus {
    outline: none;
  }
}

.green {
  color: $mainColor;
}

.blue {
  color: $mainColor;
}

.primary-btn-custom {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  padding: 13px 63px 12px 57px;
  background-color: $mainColor;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
  text-decoration: none;
  transition: 0.3s;
  border: 2px solid $mainColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    vertical-align: middle;
  }
  &:hover {
    color: $mainColor;
    background-color: #fff;
  }
}

.secondary-btn {
  color: #000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  padding: 13px 63px 12px 57px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
  text-decoration: none;
  transition: 0.3s;
  border: 2px solid #f2f2f2;
  cursor: pointer;
  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
}

.text-btn {
  color: $mainColor;
  transition: 0.3s;
  text-decoration: none;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
}

button,
input {
  outline: none !important;
}

.container {
  margin: 20px auto;
  > h3 {
    margin: 20px auto;
    color: #595959;
  }
}

.dropdown-menu-custom {
  .MuiPaper-root {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
    border-radius: 0;
    overflow: visible;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent #fff transparent;
    }
    ul {
      padding: 0;
      width: 200px;
      li {
        line-height: 39px;
        svg {
          margin-right: 12px;
        }
        a {
          color: #000;
          text-decoration: none;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }
  }
}

._loading_overlay_wrapper {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f800;
  z-index: 1500;
}

._loading_overlay_overlay {
  background: transparent !important;
}

._loading_overlay_content {
  top: 40%;
  left: 48%;
  z-index: 1000;
  position: absolute;
  color: $mainColor !important;
  svg {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite !important;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.bread-crumbs {
  margin-top: 20px;
}

.bread-crumbs a {
  text-decoration: underline;
}

.bread-crumbs a,
.bread-crumbs svg,
.bread-crumbs p {
  color: $mainColor;
  &:hover {
    color: $mainColor;
  }
}

.bread-crumbs svg {
  width: 20;
  height: 20;
  margin-right: 5px;
}

.bread-crumbs a,
.bread-crumbs p {
  display: flex;
}

.MuiBreadcrumbs-separator {
  color: $mainColor;
}

.disabled {
  color: #bebebe;
  cursor: auto !important;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: unset;
}

h2 {
  font-size: 1.5rem;
}

#item-add-menu .MuiPaper-root {
  transform: translate(-9px, 60px) !important;
}

.full-width {
  width: 100%;
}
